import type { ToastStore } from '@skeletonlabs/skeleton';
import type { Notification } from '@tickrr/db/types';

import { invalidate } from '$app/navigation';
import { logger as globalLogger } from '@tickrr/lib/logger';

type NotificationMessage = {
	data: Notification;
	event: 'notification';
	status: 'ok';
	symbol: string;
};

type HeartbeatMessage = {
	event: 'heartbeat';
	status: 'ok';
	timestamp: string;
};

type ErrorMessage = {
	event: 'error';
	message: string;
	status: 'error';
};

type NotificationWebsocketMessage = ErrorMessage | HeartbeatMessage | NotificationMessage;

const logger = globalLogger.child({
	module: 'NotificationWebsocketClient'
});

export class NotificationsWebsocketClient {
	private HEARTBEAT_INTERVAL_MS = 10000;

	private heartbeatInterval: NodeJS.Timeout | null = null;
	private isConnected = false;
	private notificationsThisSession: Set<Notification> = new Set();
	private pyServerWsUrl: string;
	private socket: WebSocket | null = null;

	constructor(pyServerWsUrl: string) {
		this.pyServerWsUrl = pyServerWsUrl;
	}

	connect(profileId: string, toastStore: ToastStore) {
		if (this.isConnected) {
			logger.debug('Notifications WebSocket connection already open. Not connecting.');
			return;
		}

		try {
			this.socket = new WebSocket(this.pyServerWsUrl + '/ws/notifications');
		} catch (err) {
			logger.error({ err }, 'Error creating WebSocket');
		}

		if (!this.socket) {
			logger.error('WebSocket connection not open. Please try again.');
			return;
		}

		this.socket.onopen = () => {
			logger.debug('Notifications WebSocket connection opened.');
			this.isConnected = true;
			this.sendConnectMessage(profileId);
			this.startHeartbeat();
		};
		this.socket.onmessage = async (event) => {
			const message = JSON.parse(event.data) as NotificationWebsocketMessage;
			if (message.event === 'notification') {
				if (message.status === 'ok') {
					logger.debug('Received notification:', message.data);
					this.notificationsThisSession.add(message.data);

					// Trigger toast.
					const actionUrl = message.data.action_url;
					toastStore.trigger({
						autohide: false,
						classes: 'toast-success',
						message: `
						<div class="flex gap-x-2">
							<div>
								<b class="text-surface-50">${message.data.title}</b><br />${message.data.body}
							</div>
							${actionUrl ? `<a href="${actionUrl}" class="btn ml-2 variant-filled">View</a>` : ''}
						</div>
						`
					});

					// Reload page data (if we are on the `/notifications` page)
					await invalidate('notifications::page');
				} else {
					logger.warn('Received notification websocket message with error:', message);
				}
			} else if (message.event === 'heartbeat') {
				logger.debug('Received heartbeat:', message);
			} else {
				logger.warn('Received unexpected websocket message:', message);
			}
		};
		this.socket.onclose = () => {
			logger.debug('WebSocket connection closed.');
			this.isConnected = false;
		};
		this.socket.onerror = (error) => {
			logger.error('WebSocket error:', error);
		};
	}

	disconnect() {
		this.stopHeartbeat();
		if (this.isConnected && this.socket) {
			this.socket.close();
		}
	}

	sendConnectMessage(profileId: string) {
		if (!this.socket) {
			logger.error('WebSocket connection not open. Please try again.');
			return;
		}
		this.socket.send(JSON.stringify({ event: 'connect', profileId }));
	}

	sendHeartbeat() {
		logger.debug('Sending heartbeat to server...');
		const heartbeat: HeartbeatMessage = {
			event: 'heartbeat',
			status: 'ok',
			timestamp: new Date().toISOString()
		};
		if (!this.socket) {
			logger.error('WebSocket connection not open. Please try again.');
			return;
		}
		this.socket.send(JSON.stringify(heartbeat));
	}

	private startHeartbeat() {
		this.heartbeatInterval = setInterval(() => {
			this.sendHeartbeat();
		}, this.HEARTBEAT_INTERVAL_MS);
	}

	private stopHeartbeat() {
		if (this.heartbeatInterval) {
			clearInterval(this.heartbeatInterval);
			this.heartbeatInterval = null;
		}
	}
}
