import type { TDTypes } from '@tickrr/twelvedata';

import { logger as globalLogger } from '@tickrr/lib/logger';
import { type Writable, writable } from 'svelte/store';

export type InboundRtpWebsocketMessage = TDTypes.Responses['WebSocketEndpoints']['RealTimePrice'];
export type OutboundRtpWebsocketMessage = {
	event: 'subscribe' | 'unsubscribe';
	symbols: string[];
};

const logger = globalLogger.child({
	module: 'RTPWebsocketClient'
});

export class RtpWebsocketClient {
	private connectPromise: Promise<void> | null = null;
	private pyServerWsUrl: string;
	private socket: WebSocket | null = null;
	private socketOpenPromise: Promise<void> | null = null;
	private subscriptions: Record<string, { refCount: number }> = {};
	isConnected: Writable<boolean> = writable(false);
	latestMessage: Writable<InboundRtpWebsocketMessage | null> = writable(null);

	constructor(pyServerWsUrl: string) {
		this.pyServerWsUrl = pyServerWsUrl;
	}

	close() {
		if (
			this.socket &&
			(this.socket.readyState === WebSocket.OPEN || this.socket.readyState === WebSocket.CONNECTING)
		) {
			this.socket.close();
		}
	}

	async subscribeToSymbol(symbol: string) {
		await this.connectIfNeeded();
		logger.debug(`Subscribing to symbol: ${symbol}`);
		if (!this.subscriptions[symbol]) {
			this.subscriptions[symbol] = { refCount: 0 };
			await this.sendMessage({ event: 'subscribe', symbols: [symbol] });
		}
		this.subscriptions[symbol].refCount++;
	}

	async unsubscribeFromSymbol(symbol: string) {
		if (!this.socket) {
			logger.warn(`No active connection to unsubscribe from symbol: ${symbol}`);
			return;
		}
		logger.debug(`Unsubscribing from symbol: ${symbol}`);
		if (!this.subscriptions[symbol]) {
			logger.warn(`No subscription found for symbol: ${symbol}`);
			return;
		}

		this.subscriptions[symbol].refCount--;
		if (this.subscriptions[symbol].refCount <= 0) {
			delete this.subscriptions[symbol];
			await this.sendMessage({ event: 'unsubscribe', symbols: [symbol] });
		}

		if (Object.keys(this.subscriptions).length === 0) {
			this.close();
		}
	}

	private connect(): Promise<void> {
		return new Promise((resolve, reject) => {
			this.socket = new WebSocket(this.pyServerWsUrl + '/ws/rtp');
			this.socketOpenPromise = new Promise((resolveOpen) => {
				this.socket!.onopen = () => {
					logger.debug('Real-time Prices WebSocket connection opened.');
					resolveOpen();
					resolve();
					this.isConnected.set(true);
				};
			});
			this.socket.onmessage = (event) => {
				const message = JSON.parse(event.data) as InboundRtpWebsocketMessage;
				logger.debug('Real-time Prices WebSocket message received:', message);
				this.latestMessage.set(message);
			};
			this.socket.onclose = () => {
				logger.debug('Real-time Prices WebSocket connection closed.');
				this.socket = null;
				this.socketOpenPromise = null;
				this.connectPromise = null;
				this.isConnected.set(false);
			};
			this.socket.onerror = (error) => {
				logger.error('Real-time Prices WebSocket error:', error);
				reject(error);
			};
		});
	}

	private async connectIfNeeded() {
		if (Object.keys(this.subscriptions).length > 0 && !this.socket) {
			if (!this.connectPromise) {
				this.connectPromise = this.connect();
			}
			await this.connectPromise;
		}
	}

	private async sendMessage(message: OutboundRtpWebsocketMessage) {
		await this.connectIfNeeded();
		await this.socketOpenPromise;
		if (this.socket && this.socket.readyState === WebSocket.OPEN) {
			this.socket.send(JSON.stringify(message));
			return;
		} else {
			logger.warn('Real-time Prices WebSocket is not open. Message not sent:', message);
			return;
		}
	}
}
