import type { Readable } from 'svelte/store';

import { localStorageStore } from '@skeletonlabs/skeleton';
import { getContext, setContext } from 'svelte';
import { writable as _writable, type Writable, readable } from 'svelte/store';

import type { CustomWritable, CustomWritableInitFn } from '../types/CustomWritable';

/**
 * Initializes a contextual writable store with the specified key, initial value, and optional writable function.
 * @template T - The type of the store value.
 * @param key - The key used to identify the store in the context.
 * @param initialValue - The initial value of the store.
 * @param customWritable - The writable function used to create the store. Defaults to Sveltekit's `writable` function.
 * This is useful if you want to define a custom store.
 * @returns The initialized contextual writable store.
 *
 * @example with custom store
 *
 * ```typescript
 *    import { initContextualWritableStore } from "@tickrr/ui/stores";
 *   import { writable } from "svelte/store";
 *
 * 	type Val = foo;
 *
 *  function initCustomWritable<Val, {
 * 		customIncrement: () => void;
 * }> (initialValue) {
 *      const customWritable = writable(0);
 *      return {
 *          ...customWritable,
 *          customIncrement: () => customWritable.update((n) => n + 1)
 *      }
 *  }
 *
 * export const customStore = initContextualWritableStore("customStore", 0, customWritable);
 * ```
 */
export function initContextualWritableStore<
	T,
	O extends object,
	I extends CustomWritableInitFn<T, O>
>(key: string, initialValue: T, customWritable: I = _writable as I): CustomWritable<T, O> {
	const store = customWritable(initialValue);
	return setContext(key, store);
}

export function initContextualReadableStore<T>(key: string, initialValue: T): Readable<T> {
	const store = readable(initialValue);
	return setContext(key, store);
}

/**
 * Initializes a contextual local storage store with the specified key and initial value.
 * @param key - The key used to identify the store in local storage.
 * @param initialValue - The initial value of the store.
 * @returns The initialized contextual local storage store.
 */
export function initContextualLocalStorageStore<T>(key: string, initialValue: T): Writable<T> {
	const store = localStorageStore<T>(`tickrr.${key}`, initialValue);
	return setContext(key, store);
}

/**
 * Retrieves a contextual writable store by its key.
 *
 * @template T - The type of the store value.
 * @param key - The key of the store.
 * @returns  The contextual writable store.
 * @throws {Error} - If the store with the specified key is not initialized.
 *
 * @example
 *
 * ```typescript
 *
 *   // store.js
 *   import { getContextualWritableStore } from "@tickrr/ui/stores";
 *
 *   export const getStore = getContextualWritableStore("store");
 * ```
 */
export function getContextualStore<T, S extends CustomWritable<T> = CustomWritable<T>>(
	key: string
): CustomWritable<T, S> {
	const store = getContext<CustomWritable<T, S> | undefined>(key);

	if (!store)
		throw new Error(
			`Store with key: ${key},  is not initialized. Please ensure that \`initializeStores()\` is invoked in the root layout file of this app!`
		);

	return store;
}
