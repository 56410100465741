import type { Writable } from 'svelte/store';

import { z } from 'zod';

import { getContextualStore, initContextualLocalStorageStore } from './_internal';

export type FeedLayout = 'GRID' | 'LIST';

export type Settings = {
	feedLayout: FeedLayout;
};

export const settingsValidator = z.object({
	feedLayout: z.enum(['LIST', 'GRID'])
});

export const DEFAULT_SETTINGS: Settings = {
	feedLayout: 'GRID'
};

export const SETTINGS_STORE_KEY = 'settingsStore';

export type SettingsStore = Writable<Settings>;

/**
 * Initializes the settings store.
 * @returns A writable store.
 */
export function initSettingsStore(): SettingsStore {
	return initContextualLocalStorageStore(SETTINGS_STORE_KEY, DEFAULT_SETTINGS);
}

/**
 * Retrieves the `settingsStore`.
 *
 * This can *ONLY* be called from the **top level** of components!
 *
 * @example
 * ```svelte
 * <script>
 * 	import { getSettingsStore } from "@tickrr/ui/stores";
 *
 * 	const settingsStore = getSettingsStore();
 *
 * 	settingsStore.set(true);
 * </script>
 * ```
 */
export function getSettingsStore(): SettingsStore {
	return getContextualStore<Settings>(SETTINGS_STORE_KEY);
}
